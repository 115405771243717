import 'angular:locale/data:vi-VN';
import 'angular:locale/data:ja-JP';
import 'angular:locale/data:ko-KR';
import 'angular:locale/data:zh-TW';
import 'angular:locale/data:zh-CN';
import 'angular:locale/data:fr-CA';
import 'angular:locale/data:pt-BR';
import 'angular:locale/data:es-MX';
import 'angular:locale/data:en-US';
import 'angular:locale/data:en-GB';
import './apps/customer-portal-frontend/src/polyfills.ts';(globalThis.$localize ??= {}).locale = "___NG_LOCALE_INSERT___";
